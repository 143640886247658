import vertexShader from '../shaders/vertex/general.vert?raw';
import {
  EventInterface,
  EventInterfaceObject,
  RequestInterval,
  RequestIntervalInterface,
  Throttle,
} from '@splidejs/splide';
import { Renderer } from './Renderer';
import { Material } from './Material';
import { ShaderCarouselOptions, TextureSource } from '../types';
import { DEFAULTS } from '../constants/defaults';
import { clamp } from '../utils';


/**
 * The class for building a carousel with using the GLSL shader for transition.
 *
 * @since 0.0.1
 */
export class ShaderCarousel {
  /**
   * The canvas element to render the carousel to.
   */
  readonly canvas: HTMLCanvasElement;

  /**
   * A Renderer instance.
   */
  readonly renderer: Renderer;

  /**
   * A custom material instance.
   */
  readonly material: Material;

  /**
   * An EventInterfaceObject instance.
   */
  protected readonly event: EventInterfaceObject;

  /**
   * Holds options.
   */
  protected readonly options: ShaderCarouselOptions;

  /**
   * The active RequestInterval instance.
   */
  protected interval: RequestIntervalInterface | undefined;

  /**
   * The ShaderCarousel constructor.
   *
   * @param canvas         - A canvas element to render the carousel.
   * @param fragmentShader - A fragment shader.
   * @param options        - Optional. An object with options.
   */
  constructor( canvas: HTMLCanvasElement, fragmentShader: string, options: ShaderCarouselOptions = {} ) {
    this.canvas   = canvas;
    this.options  = Object.assign( {}, DEFAULTS, options );
    this.event    = EventInterface();
    this.material = new Material( options.vertexShader || vertexShader, fragmentShader, options.sources, options.mask );
    this.renderer = new Renderer( canvas, this.material.material );
  }

  /**
   * Mounts the carousel.
   *
   * @param sources - Optional. An array with texture sources.
   * @param onReady - Optional. Called when all image are loaded.
   */
  mount( sources?: TextureSource[], onReady?: () => void ): void {
    sources && this.material.add( sources );

    const { preDecoding } = this.options;

    this.material.load()
      .then( () => {
        this.resize();
        preDecoding === 'load' && this.decode();
        preDecoding === 'nearby' && this.decodeAround();
        onReady && onReady();
      } )
      .catch( console.error );

    this.resize();
    this.listen();
  }


  /**
   * Asynchronously mounts the carousel.
   *
   * @param sources - Optional. An array with texture sources.
   *
   * @return A promise resolved when all images are loaded.
   */
  mountAsync( sources?: TextureSource[] ): Promise<void> {
    return new Promise( resolve => {
      this.mount( sources, resolve );
    } );
  }

  /**
   * Destroys the instance.
   */
  destroy(): void {
    this.event.destroy();
    this.material.destroy();
  }

  /**
   * Goes to the specified index.
   *
   * @param index   - An index to go.
   * @param reverse - Optional. Explicitly sets the transition direction.
   */
  go( index: number, reverse?: boolean ): void {
    const auto = reverse === undefined;
    const curr = this.material.getIndex();
    index = clamp( index, 0, this.getLength() - 1 );

    if ( index !== curr ) {
      this.material.setIndex( index, reverse );
      this.transition( ( auto && curr > index ) || !! reverse );
    }
  }

  /**
   * Resizes the slider to fit the content to the parent element.
   */
  resize(): void {
    const width  = this.getWidth();
    const height = this.getHeight();

    if ( width && height ) {
      this.renderer.setSize( width, height );
      this.material.setSize( width, height );
    }

    this.render();
  }

  /**
   * Manually sets the progress.
   *
   * @internal
   *
   * @param progress - Progress rate to set from 0 to 1.
   */
  setProgress( progress: number ): void {
    this.material.setProgress( clamp( progress, 0, 1 ) );
  }

  /**
   * Returns the current width.
   *
   * @return The current width. If the parent of the canvas is not available, this always returns 0.
   */
  getWidth(): number {
    return this.canvas.parentElement?.clientWidth || 0;
  }

  /**
   * Returns the current height.
   *
   * @return The current height. If the parent of the canvas is not available, this always returns 0.
   */
  getHeight(): number {
    return this.canvas.parentElement?.clientHeight || 0;
  }

  /**
   * Returns the number of textures.
   */
  getLength(): number {
    return this.material.getLength();
  }

  /**
   * Manually decodes textures beforehand.
   */
  protected decode(): void {
    this.renderer.decode( this.material.textures.map( texture => texture.texture ) );
  }

  /**
   * Manually decodes textures around the current texture.
   */
  protected decodeAround(): void {
    const { material } = this;
    const length = material.getLength();

    if ( length ) {
      const { textures } = this.material;
      const index = material.getIndex();
      const next  = ( index + 1 ) % length;
      const prev  = ( index - 1 + length ) % length;

      this.renderer.decode( [ textures[ next ].texture, textures[ prev ].texture ] );
    }
  }

  /**
   * Renders the scene to the canvas.
   */
  protected render(): void {
    this.renderer.render();
  }

  /**
   * Listens to some events.
   * Needs to resize the canvas and the scene when the window is resized.
   */
  protected listen(): void {
    this.event.bind( window, 'resize', Throttle( () => {
      this.resize();
      this.render();
    } ) );
  }

  /**
   * Changes the carousel forwards or backwards.
   *
   * @param reverse - Determines whether to go to the prev slide or the next one.
   */
  protected transition( reverse: boolean ): void {
    if ( this.interval ) {
      this.interval.cancel();
    }

    const { speed  = 1000 } = this.options;

    this.interval = RequestInterval(
      speed,
      this.onTransitionEnd.bind( this ),
      this.onProgress.bind( this, reverse ),
      1
    );

    this.interval.start();
  }

  /**
   * Called every time when the progress rate changes.
   * Do not forget to call the render method to update the shader.
   *
   * @param reverse  - `true` will be passed for backwards transition.
   * @param progress - Progress rate.
   */
  protected onProgress( reverse: boolean, progress: number ): void {
    const { easingFunc = t => 1 - Math.pow( 1 - t, 4 ) } = this.options;
    progress = easingFunc( progress );
    this.setProgress( reverse ? 1 - progress : progress );
    this.render();
  }

  /**
   * Called when transition ends.
   */
  protected onTransitionEnd(): void {
    this.interval = undefined;
    this.options.preDecoding === 'nearby' && this.decodeAround();
  }
}