import Splide, { CLASS_INITIALIZED, ComponentConstructor, EventInterface } from '@splidejs/splide';
import { SplideShaderCarouselOptions as Options } from '../types';
import { BackgroundShaderCarousel } from '../components/BackgroundShaderCarousel';
import { EVENT_SHADER_CAROUSEL_ERROR, EVENT_SHADER_CAROUSEL_READY } from '../constants/events';
import { DEFAULTS } from '../constants/defaults';


/**
 * The frontend class for a Splide carousel with shader transition.
 *
 * @since 0.0.1
 */
export class SplideShaderCarousel extends Splide {
  /**
   * Checks if WebGL is supported or not.
   *
   * @return `true` if webGL is available, or otherwise `false`.
   */
  static isAvailable(): boolean {
    try {
      const canvas  = document.createElement( 'canvas' );
      const context = canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' );
      return !! ( WebGLRenderingContext && context );
    } catch ( e ) {
      return false;
    }
  }

  /**
   * Keeps the fragment shader.
   */
  readonly fragmentShader: string;

  /**
   * The SplideShaderTransition constructor.
   *
   * @param target         - The selector for the target element, or the element itself.
   * @param fragmentShader - The fragment shader.
   * @param options        - Optional. An object with options.
   */
  constructor( target: string | HTMLElement, fragmentShader: string, options?: Options ) {
    super( target, Object.assign( {}, DEFAULTS, options ) );
    this.fragmentShader = fragmentShader;
  }

  /**
   * Initializes the instance.
   *
   * @param Extensions - Optional. An object with extensions.
   */
  mount( Extensions: Record<string, ComponentConstructor> = {} ): this {
    super.mount( Object.assign( Extensions, { BackgroundShaderCarousel } ) ); // @todo unsafe

    if ( this.options.awaitInit ) {
      this.root.classList.remove( CLASS_INITIALIZED );
    }

    return this;
  }

  /**
   * Asynchronously mounts the component.
   * The returned promise will be resolved when all textures are loaded and the shader gets ready to render the scene.
   *
   * @param Extensions - Optional. An object with extensions.
   *
   * @return A promise when the shader gets ready.
   */
  mountAsync( Extensions?: Record<string, ComponentConstructor> ): Promise<void> {
    return new Promise<void>( ( resolve, reject ) => {
      this.mount( Extensions );
      const { on } = EventInterface( this );
      on( EVENT_SHADER_CAROUSEL_READY, resolve );
      on( EVENT_SHADER_CAROUSEL_ERROR, reject );
    } );
  }
}