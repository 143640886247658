import {
  BaseComponent,
  CLASS_INITIALIZED,
  CLASS_LOADING,
  CLASS_SR,
  Components,
  EventInterface,
  LOOP,
  SlideComponent,
} from '@splidejs/splide';
import { SplideShaderCarousel as Splide } from '../core/SplideShaderCarousel';
import { SplideShaderCarouselOptions as Options, TextureSource } from '../types';
import { ShaderCarousel } from '../classes/ShaderCarousel';
import { EVENT_SHADER_CAROUSEL_ERROR, EVENT_SHADER_CAROUSEL_READY } from '../constants/events';
import { DEFAULTS } from '../constants/defaults';


/**
 * The component for displaying a shader carousel as a background of the foreground carousel.
 *
 * @constructor
 * @since 0.0.1
 *
 * @param Splide     - A SplideShaderCarousel instance.
 * @param Components - A collection of components.
 * @param options    - Options.
 *
 * @return A BackgroundShaderCarousel object.
 */
export function BackgroundShaderCarousel( Splide: Splide, Components: Components, options: Options ): BaseComponent {
  const { on, emit } = EventInterface( Splide );
  const { track } = Components.Elements;
  const canvas = document.createElement( 'canvas' );

  /**
   * The ShaderCarousel instance.
   */
  let shaderCarousel: ShaderCarousel | undefined;

  /**
   * Stores texture sources.
   */
  const sources: TextureSource[] = [];

  /**
   * Keeps image elements used for shader textures.
   */
  const images: HTMLImageElement[] = [];

  /**
   * Stores SR fields.
   */
  const srFields: HTMLSpanElement[] = [];

  /**
   * Called when the component is mounted.
   */
  function mount(): void {
    initCanvas();
    initCarousel();
    initSources();
    initMaterial();
    mountCarousel();
  }

  /**
   * Mounts the shader carousel.
   */
  function mountCarousel(): void {
    if ( sources.length > 1 ) {
      const { classList } = Splide.root;
      classList.add( CLASS_LOADING );

      shaderCarousel?.mountAsync( sources )
        .then( () => {
          canvas.style.visibility = 'visible';
          classList.add( CLASS_INITIALIZED );
          classList.remove( CLASS_LOADING );
          emit( EVENT_SHADER_CAROUSEL_READY );
        } )
        .catch( e => {
          emit( EVENT_SHADER_CAROUSEL_ERROR );
          throw new Error( e );
        } );

      listen();
    } else {
      console.error( 'Requires at least 2 images.' );
      emit( EVENT_SHADER_CAROUSEL_ERROR );
    }
  }

  /**
   * Listens to some events.
   */
  function listen(): void {
    on( 'move', ( index, prev ) => {
      const { length } = Splide;
      let reverse;

      if ( options.continuous || Splide.is( LOOP ) ) {
        if ( prev === length - 1 && index === 0 ) {
          reverse = false;
        } else if ( prev === 0 && index === length - 1 ) {
          reverse = true;
        }
      }

      shaderCarousel?.go( index, reverse );
    } );
  }

  /**
   * Destroys the component.
   */
  function destroy(): void {
    track.removeChild( canvas );

    srFields.forEach( field => {
      field.parentElement?.removeChild( field );
    } );

    images.forEach( img => {
      img.style.display = '';
    } );

    sources.length  = 0;
    srFields.length = 0;
    images.length   = 0;

    shaderCarousel?.destroy();
    shaderCarousel = undefined;
  }

  /**
   * Initializes the canvas element.
   */
  function initCanvas(): void {
    const { style } = canvas;

    style.position   = 'absolute';
    style.zIndex     = '-1';
    style.top        = '0';
    style.left       = '0';
    style.visibility = 'hidden';

    track.appendChild( canvas );
  }

  /**
   * Creates and initializes a shader carousel.
   */
  function initCarousel(): void {
    shaderCarousel = new ShaderCarousel( canvas, Splide.fragmentShader, {
      speed       : options.speed,
      mask        : options.mask,
      easingFunc  : options.easingFunc || DEFAULTS.easingFunc,
      preDecoding : options.preDecoding,
      vertexShader: options.vertexShader,
    } );
  }

  /**
   * Initializes sources.
   * If sources are not provided by options, finds them in all slides.
   */
  function initSources(): void {
    const { sources: sourcesOption } = options;

    if ( sourcesOption && sourcesOption.length ) {
      sources.push( ...sourcesOption );
    } else {
      Components.Slides.forEach( initSlide );
    }
  }

  /**
   * Initializes each slide.
   *
   * @param Slide A Slide component.
   */
  function initSlide( Slide: SlideComponent ): void {
    const img = Slide.slide.querySelector( 'img' );

    if ( img ) {
      const { alt } = img;

      if ( alt && options.keepAlt ) {
        const span = document.createElement( 'span' );
        span.textContent = alt;
        span.classList.add( CLASS_SR );
        img.parentElement?.insertBefore( span, img );
        srFields.push( span );
      }

      if ( ! Slide.isClone ) {
        sources.push( img.src );
        images.push( img );
      }

      img.style.display = 'none';
    }
  }

  /**
   * Initializes material parameters.
   */
  function initMaterial(): void {
    const { material } = options;

    if ( material ) {
      shaderCarousel?.material.setParams( {
        intensity: material.intensity,
        uvOffset : material.uvOffset,
      } );
    }
  }

  return {
    mount,
    destroy,
  };
}