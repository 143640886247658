import { ShaderMaterial, TextureLoader, Vector2, VideoTexture } from 'three';
import { IUniform } from 'three/src/renderers/shaders/UniformsLib';
import { Texture } from 'three/src/textures/Texture';
import { MaterialParameters, TextureSource } from '../types';


/**
 * The class for handling the shader and changing textures.
 *
 * @since 0.0.1
 */
export class Material {
  /**
   * The ShaderMaterial instance.
   */
  readonly material: ShaderMaterial;

  /**
   * Stores Texture instances.
   */
  readonly textures: Array<{ texture: Texture, ratio: Vector2 }> = [];

  /**
   * An object with uniforms for the shader.
   */
  protected readonly uniforms: { [ uniform: string ]: IUniform } = {
    tTexture    : { value: null },
    tNextTexture: { value: null },
    tMask       : { value: null },
    fIntensity  : { value: 0.5 },
    fProgress   : { value: 0 },
    vUVOffset   : { value: new Vector2( 1, 1 ) },
    vRatio      : { value: new Vector2( 1, 1 ) },
    vNextRatio  : { value: new Vector2( 1, 1 ) },
  };

  /**
   * Stores provided sources.
   */
  protected sources: TextureSource[];

  /**
   * A mask URL.
   */
  protected mask: string | undefined;

  /**
   * The current texture index.
   */
  protected index = 0;

  /**
   * The next texture index.
   */
  protected nextIndex = 0;

  /**
   * The Material constructor.
   *
   * @param vertexShader   - A vertex shader.
   * @param fragmentShader - A fragment shader.
   * @param sources        - Optional. An array with image URLs.
   * @param mask           - Optional. A mask URL.
   */
  constructor( vertexShader: string, fragmentShader: string, sources: TextureSource[] = [], mask?: string ) {
    this.material = new ShaderMaterial( {
      vertexShader,
      fragmentShader,
      uniforms: this.uniforms,
    } );

    this.sources = sources;
    this.mask    = mask;
  }

  /**
   * Destroys the instance.
   */
  destroy(): void {
    this.sources.length = 0;
  }

  /**
   * Adds image URLs.
   * This muse be called before `load()`.
   *
   * @param sources - An array with texture sources.
   */
  add( sources: TextureSource[] ): void {
    this.sources.push( ...sources );
  }

  /**
   * Starts loading textures.
   *
   * @return A promise that is resolved with Texture instances when all images get ready.
   */
  load(): Promise<Texture[]> {
    return this.loadMask().then( () => {
      return this.loadSources()
        .then( textures => {
          this.textures.push( ...textures.map( texture => ( { texture, ratio: new Vector2( 1, 1 ) } ) ) );
          this.uniforms.tTexture.value = textures[ 0 ] || null;
          return textures;
        } );
    } );
  }

  /**
   * Updates the dimension used in the shader.
   *
   * @param width  - The carousel width.
   * @param height - The carousel height.
   */
  setSize( width: number, height: number ): void {
    this.textures.forEach( ( texture, index ) => {
      const aspect = ( width / height ) || 1;
      const [ imageWidth, imageHeight ] = this.getTextureDimension( texture.texture );

      texture.ratio = new Vector2(
        Math.min( aspect / ( imageWidth / imageHeight ), 1.0 ),
        Math.min( ( 1 / aspect ) / ( imageHeight / imageWidth ), 1.0 )
      );

      if ( index === this.index ) {
        this.uniforms.vRatio.value = texture.ratio;
      }

      if ( index === this.nextIndex ) {
        this.uniforms.vNextRatio.value = texture.ratio;
      }
    } );
  }

  /**
   * Sets a new index and updates textures in the shader.
   *
   * @param index   - A new index.
   * @param reverse - Optional. Explicitly sets the transition direction.
   */
  setIndex( index: number, reverse?: boolean ): void {
    const { textures, index: curr } = this;

    if ( 0 <= index && index < textures.length && curr !== index ) {
      const auto = reverse === undefined;

      if ( ( auto && curr > index ) || reverse ) {
        this.setProgress( 1 );
        this.setTexture( index, curr );
        this.nextIndex = curr;
      } else {
        this.setProgress( 0 );
        this.setTexture( curr, index );
        this.nextIndex = index;
      }

      this.index = index;
    }
  }

  /**
   * Returns the current texture index.
   */
  getIndex(): number {
    return this.index;
  }

  /**
   * Applies current and next textures and aspect ratios.
   *
   * @param curr - A current index.
   * @param next - A next index.
   */
  setTexture( curr: number, next: number ): void {
    const currTexture = this.textures[ curr ];
    const nextTexture = this.textures[ next ];

    if ( currTexture && nextTexture ) {
      const { uniforms } = this;

      uniforms.tTexture.value     = currTexture.texture;
      uniforms.vRatio.value       = currTexture.ratio;
      uniforms.tNextTexture.value = nextTexture.texture;
      uniforms.vNextRatio.value   = nextTexture.ratio;
    }
  }

  /**
   * Sets transition progress (0-1).
   *
   * @param progress - Progress rate.
   */
  setProgress( progress: number ): void {
    this.uniforms.fProgress.value = progress;
  }

  /**
   * Sets material parameters.
   *
   * @param params - Parameters to update.
   */
  setParams( params: MaterialParameters ): void {
    const { intensity = 0.5, uvOffset = [ 1, 1 ] } = params;
    this.uniforms.fIntensity.value = intensity;
    this.uniforms.vUVOffset.value = uvOffset;
  }

  /**
   * Returns the number of textures.
   */
  getLength(): number {
    return this.textures.length;
  }

  /**
   * Load sources and create textures.
   *
   * @return A promise resolved when sources are loaded.
   */
  protected loadSources(): Promise<Texture[]> {
    return Promise.all( this.sources.map( source => {
      if ( source instanceof HTMLVideoElement ) {
        return this.loadVideo( source );
      }

      return new TextureLoader()
        .loadAsync( source instanceof HTMLImageElement ? source.src : source )
        .then( texture => {
          texture.needsUpdate = true;
          return texture;
        } );
    } ) );
  }

  /**
   * Loads the video texture.
   * 2 means `HAVE_CURRENT_DATA`.
   *
   * @link https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
   *
   * @param video - A video element.
   *
   * @return A promise resolved when the texture is available.
   */
  protected loadVideo( video: HTMLVideoElement ): Promise<VideoTexture> {
    return new Promise<VideoTexture>( resolve => {
      const texture = new VideoTexture( video );
      texture.needsUpdate = true;

      if ( video.readyState >= 2 ) {
        resolve( texture );
      } else {
        video.addEventListener( 'canplay', function onCanplay() {
          resolve( texture );
          video.removeEventListener( 'canplay', onCanplay );
        } );
      }
    } );
  }

  /**
   * Loads the mask texture if available.
   *
   * @return A promise resolved when the texture is loaded.
   */
  protected loadMask(): Promise<void> {
    if ( ! this.mask ) {
      return Promise.resolve();
    }

    return new TextureLoader().loadAsync( this.mask ).then( mask => {
      this.uniforms.tMask.value = mask;
    } );
  }

  /**
   * Returns the dimension of the provided texture.
   *
   * @param texture - A Texture instance.
   *
   * @return A tuple as `[ width, height ]`.
   */
  protected getTextureDimension( texture: Texture ): [ number, number ] {
    const { image } = texture;
    return image instanceof HTMLVideoElement
      ? [ image.videoWidth, image.videoHeight ]
      : [ image.width, image.height ];
  }
}